export const PATHS = {
    WELCOME: '/welcome',
    LOGIN: '/login',
    TWITTER_CALLBACK: '/twitter/callback',
    LOGIN_INTERNAL: '/login/internal',
    SIGN_UP: '/signup',
    SIGN_UP_INTERNAL: '/signup/internal',
    HOME: '/',
    VIEW_POST: '/posts/:id',
    WRITE_POST: '/write-post',
    PROFILE: '/profile',
    HISTORTY: '/profile/history',
    REPUTATION: '/profile/reputation',
}
