"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.shiftBits = exports.decodeEpochKeyControl = void 0;
const utils_1 = require("@unirep/utils");
const decodeEpochKeyControl = (control) => {
    let accBits = BigInt(0);
    const nonce = (0, exports.shiftBits)(control, accBits, utils_1.NONCE_BITS);
    accBits += utils_1.NONCE_BITS;
    const epoch = (0, exports.shiftBits)(control, accBits, utils_1.EPOCH_BITS);
    accBits += utils_1.EPOCH_BITS;
    const attesterId = (0, exports.shiftBits)(control, accBits, utils_1.ATTESTER_ID_BITS);
    accBits += utils_1.ATTESTER_ID_BITS;
    const revealNonce = (0, exports.shiftBits)(control, accBits, utils_1.REVEAL_NONCE_BITS);
    accBits += utils_1.REVEAL_NONCE_BITS;
    const chainId = (0, exports.shiftBits)(control, accBits, utils_1.CHAIN_ID_BITS);
    return {
        nonce,
        epoch,
        attesterId,
        revealNonce,
        chainId,
    };
};
exports.decodeEpochKeyControl = decodeEpochKeyControl;
const shiftBits = (data, shiftBits, variableBits) => {
    return (data >> shiftBits) & ((BigInt(1) << variableBits) - BigInt(1));
};
exports.shiftBits = shiftBits;
