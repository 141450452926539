"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReportIdentityProof = void 0;
const circuits_1 = require("@unirep/circuits");
const types_1 = require("./types");
/**
 * Verify the voter identity for voting the report
 */
class ReportIdentityProof extends circuits_1.BaseProof {
    constructor(publicSignals, proof, prover) {
        super(publicSignals, proof, prover);
        this.input = {
            reportNullifier: 0,
            reportId: 1,
            attesterId: 2,
            epoch: 3,
            stateTreeRoot: 4,
        };
        this.reportNullifier = this.publicSignals[this.input.reportNullifier];
        this.reportId = this.publicSignals[this.input.reportId];
        this.attesterId = this.publicSignals[this.input.attesterId];
        this.epoch = this.publicSignals[this.input.epoch];
        this.stateTreeRoot = this.publicSignals[this.input.stateTreeRoot];
        this.circuit = types_1.UnirepSocialCircuit.reportIdentityProof;
    }
}
exports.ReportIdentityProof = ReportIdentityProof;
