"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReportNonNullifierProof = void 0;
const circuits_1 = require("@unirep/circuits");
const types_1 = require("./types");
const utils_1 = require("./utils");
/**
 *  Verify the reporter / poster identity for claiming reputation
 */
class ReportNonNullifierProof extends circuits_1.BaseProof {
    constructor(_publicSignals, _proof, prover) {
        super(_publicSignals, _proof, prover);
        this.output = {
            control: 0,
            currentEpochKey: 1,
        };
        this.input = {
            reportedEpochKey: 2,
        };
        this.reportedEpochKey = BigInt(_publicSignals[this.input.reportedEpochKey]);
        this.currentEpochKey = BigInt(_publicSignals[this.output.currentEpochKey]);
        this.control = BigInt(this.publicSignals[this.output.control]);
        const { nonce, epoch, attesterId, revealNonce, chainId } = (0, utils_1.decodeEpochKeyControl)(this.control);
        this.nonce = nonce;
        this.epoch = epoch;
        this.attesterId = attesterId;
        this.revealNonce = revealNonce;
        this.chainId = chainId;
        this.circuit =
            types_1.UnirepSocialCircuit.reportNonNullifierProof;
    }
}
exports.ReportNonNullifierProof = ReportNonNullifierProof;
